import React, {useEffect, useState} from 'react';
import {Button, Input, InputNumber, message, Modal, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import CartTable from "./CartTable";
import './Cash.css'
import CashCounterModal from "./CashCounterModal";
import {
    addCartManyItems,addToReceiptCache,
    addUniversalItem,
    changeCartByCode,
    clearCart,
    flushMessage
} from "../../store/mainSlice";
import FastCashComp from "./FastCashComp";
import CashNavigator from "./CashNavigator";
import qz from 'qz-tray';
import {SellItemInterface} from "../../store/Interfaces";
import API from "../../API/API";
import {BarcodeOutlined, FontSizeOutlined, PlusCircleOutlined} from "@ant-design/icons";
import KeyboardComp from "./KeyboardComp";
import AddItem from "../admin/addItem/AddItem";

export const printReceipt = (cashItems: {items:SellItemInterface[]}, money: number, mode: 1 | 2 | 3, cashValue: number, user:String) =>{
    qz.websocket.connect()
        .then(function() {
            return qz.printers.find("80mm")
        }).then((printer)=> {
        console.log("printers: ", printer)
        let config
        if(Array.isArray(printer)){
            // config = qz.configs.create(printer[0]);       // Create a default config for the found printer
            config = qz.configs.create(printer[0], { encoding: 'Cp866' });       // Create a default config for the found printer
        }else {
            // config = qz.configs.create(printer);       // Create a default config for the found printer
            config = qz.configs.create(printer, { encoding: 'Cp866'});       // Create a default config for the found printer
        }
        console.log("conf: ", config)


        let date = new Date()
        let day
        if(date.getDay() === 1){
            day = "Понедельник"
        }
        if(date.getDay() === 2){
            day = "Вторник"
        }
        if(date.getDay() === 3){
            day = "Среда"
        }
        if(date.getDay() === 4){
            day = "Четверг"
        }
        if(date.getDay() === 5){
            day = "Пятница"
        }
        if(date.getDay() === 6){
            day = "Суббота"
        }
        if(date.getDay() === 0){
            day = "Воскресенье"
        }

        const uni = day + " " + date.toLocaleDateString() + " " + date.toLocaleTimeString()

        const data = [
            '\x1B' + '\x40',          // init
            // '\x1B' + '\x74' + '\x11', //Cyrillic
            '\x1B'+ '\x74' +'\x07',
            '\x1B' + '\x61' + '\x31', // center align

            'Shynar Кенсе' + '\x0A',
            '\x0A',                   // line break
            'https://shynar.info' + '\x0A',     // text and line break
            '\x0A',                   // line break
            uni + '\x0A',
            '\x0A',                   // line break
            'Чек: #, Кассир: ' + user + '\x0A',
            '\x0A',
        ]

        cashItems.items.forEach((item, index)=>{
            data.push('\x1B' + '\x61' + '\x30')//left
            data.push((index + 1) + ". " + item.name)
            data.push('\x0A')

            if(mode === 1){
                let startS = "  " + item.quantity + " x " + item.masterPrice + "тг"
                let endS = String(item.quantity * item.masterPrice + ".00тг").padStart(48)
                endS = endS.slice(startS.length)
                data.push(startS + endS)
            }
            else if(mode === 2){
                let startS = "  " + item.quantity + " x " + item.lowPrice + "тг"
                let endS = String(item.quantity * item.lowPrice + ".00тг").padStart(48)
                endS = endS.slice(startS.length)
                data.push(startS + endS)
            }
            else {
                let startS = "  " + item.quantity + " x " + item.highPrice + "тг"
                let endS = String(item.quantity * item.highPrice + ".00тг").padStart(48)
                endS = endS.slice(startS.length)
                data.push(startS + endS)
            }
            data.push('\x0A')
        })
        let x = [
            '------------------------------------------' + '\x0A',
            '\x1B' + '\x61' + '\x30', // left align
            'Сумма: ',
            '\x1B' + '\x61' + '\x32',
            cashValue + "тг" + '\x0A',
            'Принято: ' + money + "тг" + '\x0A',
            money - cashValue !== 0 ? 'Сдача: ' + (money - cashValue) + 'тг' : 'Без сдачи',
            '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',
            '\x1B' + '\x6D',///cutter
        ];

        return qz.print(config, data.concat(x));
    }).catch(error=>{
        console.log("err: ", error)
    }).finally(()=>{
        qz.websocket.disconnect().then((d)=>{
            console.log(d)
        }).catch(eer=>{
            console.log("err:", eer)
        })
    });
}

const CashPage = () => {
    const mainItems = useSelector((state: RootState) => state.main.items);
    const cashItems = useSelector((state: RootState) => state.main.cart);
    const messager = useSelector((state: RootState) => state.main.message);
    const user = useSelector((state: RootState) => state.main.user);
    const activeItem = useSelector((state: RootState) => state.main.cart.activeItem);

    // console.log("mm: ", mainItems)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isManyItemModalOpen, setManyItemModalOpen] = useState(false);
    const [isFastCashModalOpen, setFastCashModalOpen] = useState(false);
    const [cashOK, setCashOk] = useState(false);
    const [openUniProd, setOpenUniProd] = useState(false);
    const [openAddItem, setOpenAddItem] = useState(false);
    const [blockFocus, setBlockFocus] = useState(false);
    const [barcode, setBarcode] = useState("");
    const [uniPrice, setUniPrice] = useState(0);
    const [uniName, setUniName] = useState("");
    const [money, setMoney] = React.useState(0);
    const [comments, setComments] = React.useState("");
    const [lastExchange, setLastExchange] = React.useState(0);
    const [lastMoney, setLastMoney] = React.useState(0);
    const [selectValue, setSelectValue] = React.useState("");
    const [nameSearchTypeBar, setNameSearchTypeBar] = React.useState(false);

    const [messageApi, contextHolder] = message.useMessage();
    const [selectedItems, setSelectedItems] = useState<string[]>([])

    const dispatch = useDispatch();

    useEffect(() => {
        if(messager.type === "cart"){
            messageApi.open({
                type: 'error',
                content: messager.message,
            });
            dispatch(flushMessage())
        }
        if(messager.type === "cart.manyItems"){
            setManyItemModalOpen(true)
        }
    }, [messager]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        printReceipt(cashItems, money, mode, cashValue, user ? user.name: "")
        setLastExchange(money - cashValue)
        setLastMoney(money)
        let date = new Date()
        let day
        if(date.getDay() === 0){
            day = "Понедельник"
        }
        if(date.getDay() === 1){
            day = "Вторник"
        }
        if(date.getDay() === 2){
            day = "Среда"
        }
        if(date.getDay() === 3){
            day = "Четверг"
        }
        if(date.getDay() === 4){
            day = "Пятница"
        }
        if(date.getDay() === 5){
            day = "Суббота"
        }
        if(date.getDay() === 6){
            day = "Воскресенье"
        }

        const uni = day + " " + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        let margin = 0
        cashItems.items.forEach(itm=>{
            let itz = mainItems.find(itx=>itx.id === itm.id)
            if(itz) {
                if (mode === 1) {
                    margin += (itm.masterPrice - itz.price) * itm.quantity
                }
                else if(mode === 2){
                    margin += (itm.lowPrice - itz.price) * itm.quantity
                }else {
                    margin += (itm.highPrice - itz.price) * itm.quantity
                }
            }
        })
        API.createReceipt(cashItems.items, money, uni, mode, margin, comments)
            .then((data)=>{
                console.log("dd: ", data)
            }).catch(error=>{
                dispatch(addToReceiptCache({
                    cash: money,
                    cartItems: cashItems.items.map((item)=>{
                        return {name: item.name, price: mode === 1 ? item.masterPrice : mode === 2 ? item.lowPrice : item.highPrice, quantity: item.quantity}
                    }),
                    uni,
                    type: mode,
                    comments,
                    margin: margin
                }))
            })
        setComments("")
        setMoney(0)
        dispatch(clearCart())

        // dispatch(addReceiptNum())
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setMoney(0)
        setComments("")
    };

    const [mode, setMode] = useState<1 | 2 | 3>(3)

    const [typerUni, setTypeUni] = useState<string>("name")

    const cashValue = cashItems.items.reduce((accumulator, current) => {
        if(mode === 1){
            return accumulator + current.masterPrice * current.quantity
        }
        else if(mode === 2){
            return accumulator + current.lowPrice * current.quantity
        }
        return accumulator + current.highPrice * current.quantity
    }, 0)



    return (
        <div>
            {contextHolder}
            <CashNavigator setMode={setMode} mode={mode}/>
            <div style={{marginTop: 55, marginLeft: 50}}>
                <div style={{flexDirection: "row", display: "flex", marginLeft: 20, marginRight: 20, justifyContent: "space-evenly"}}>

                    <Select
                        autoFocus
                        id={"namesearcher"}
                        value={""}
                        onBlur={()=>{
                            if(isModalOpen || openUniProd || blockFocus || openAddItem) return
                            document.getElementById("namesearcher")!.focus()
                        }}
                        onChange={(event, s)=>{

                            console.log("ev: ", selectValue, " jk: ", !isNaN(Number(selectValue)))
                            if(selectValue.startsWith("sh-")) {
                                return
                            }
                            dispatch(changeCartByCode({code: event, isID: true}))
                            setSelectValue("")

                        }}
                        onSelect={(value, option)=>{
                            // console.log("event", event)
                            // dispatch(changeCartByCode({isID: false, code: option.barcode}))
                        }}
                        inputValue={selectValue}
                        filterOption={(input, option)=>{
                            // return !!(option?.name?.toLowerCase().includes(input.toLowerCase()) || option?.barcode?.toLowerCase().includes(input.toLowerCase()))
                            if(!nameSearchTypeBar) return false
                            return !!(option?.name?.toLowerCase().includes(input.toLowerCase()))
                        }}
                        onKeyDown={(event)=>{
                            if(nameSearchTypeBar){
                                // return
                            }
                            if (event.key === "Enter") {
                                console.log("enter")
                                dispatch(changeCartByCode({isID: false, code: selectValue}))
                                setSelectValue("")
                            }
                        }}
                        onSearch={(value)=>{
                            setSelectValue(value)
                        }}
                        fieldNames={{value: "id", label: "name"}}
                        showSearch

                        open={selectValue.length > 0 && nameSearchTypeBar}
                        optionFilterProp={"name"}
                        options={mainItems}
                        style={{width: "30%"}}>
                    </Select>
                    <Button onClick={()=>{setNameSearchTypeBar(s=>!s)}} type={nameSearchTypeBar ? "primary" : "default"} shape={"circle"}>
                        <FontSizeOutlined />
                    </Button>

                    <Button shape={"circle"} onClick={()=>{
                        setOpenAddItem(true)
                    }}>
                        <PlusCircleOutlined />
                    </Button>

                </div>

                {cashItems.items.length > 0 && <CartTable setBlockFocus={setBlockFocus} mode={mode} active={activeItem} keyboard={nameSearchTypeBar}/>
                }
                <div style={{position: "fixed", bottom: 0, left: 50, right: 0, height: 100, background: "#001529"}}>
                    <Button style={{fontSize: 18, color: "#001529", fontWeight: "bold", position: "absolute", left: 20, top: 10, bottom: 10, width: 160, height: 80}} onClick={()=>{
                    }}><p>Сумма<br/>{cashValue + "₸"}</p></Button>
                     <Button style={{position: "absolute", left: 200, top: 10, bottom: 10, width: 180, height: 80}} onClick={()=>{
                    }}><p>{"Последний чек"}<br/>{lastMoney + "₸ "}{"Сдача: " + lastExchange + "₸"}</p></Button>
                    <Button onClick={()=>setFastCashModalOpen(true)} style={{position: "absolute", right: 140, top: 10, bottom: 10, height: 80, width: 120}}><p>Быстрые<br/>Товары</p></Button>
                    <Button onClick={()=>{
                        setOpenUniProd(true)
                    }} style={{position: "absolute", right: 280, top: 10, bottom: 10, height: 80, width: 140}}>
                        <p>Универсальный <br/> Продукт</p>
                    </Button>
                    <Button onClick={()=>{
                        if(cashItems.items.length === 0){return}showModal()}} type={"primary"} style={{position: "absolute", right: 20, top: 10, bottom: 10, height: 80, width: 100, }}>Оплата</Button>
                </div>

            </div>
            <Modal destroyOnClose okButtonProps={{disabled: cashOK}} title={"К оплате " + cashValue + "₸"} cancelText={"Отмена"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <CashCounterModal comments={comments} setComments={setComments} money={money} setMoney={setMoney} setCashOk={setCashOk} value={cashValue}/>
            </Modal>

            <Modal width={"100%"} centered destroyOnClose title={""} cancelText={"Отмена"} open={isFastCashModalOpen}
                   onCancel={()=>{setFastCashModalOpen(false)}}
                   footer={[
                       <Button key={1} style={{height: 60, width: 100}} onClick={()=>{
                           setFastCashModalOpen(false)
                       }}>Отмена</Button>,
                       <Button key={2} type={"primary"} style={{height: 60, width: 100}} onClick={()=>{
                           setFastCashModalOpen(false)
                           if(selectedItems.length > 0) {
                               dispatch(addCartManyItems(selectedItems))
                               setSelectedItems([])
                           }
                       }}>OK</Button>]}
            >
                <FastCashComp setSelectedItems={setSelectedItems} selectedItem={selectedItems} setFastCashModalOpen={setFastCashModalOpen}/>
            </Modal>

            <Modal title={"Название нового товара"} destroyOnClose cancelText={"Отмена"} open={openUniProd}
                   onCancel={()=>{
                       setTypeUni("name")
                       setOpenUniProd(false)
                   }}
                   footer={[
                       <Button key={1} style={{height: 60, width: 100}} onClick={()=>{
                           setTypeUni("name")
                           setOpenUniProd(false)
                       }}>Отмена</Button>,
                       <Button key={2} type={"primary"} style={{height: 60, width: 100}} onClick={()=>{
                           if (uniName === "" || uniPrice === 0){
                               return
                           }
                           setTypeUni("name")
                           dispatch(addUniversalItem({name: uniName, price: uniPrice}))
                           setUniName("")
                           setUniPrice(0)
                           setOpenUniProd(false)
                       }}>OK</Button>]}

            >
                <Input onFocus={()=>{
                    setTypeUni("name")

                }} style={{marginTop:10}} placeholder={"Имя товара"} onChange={e=>{
                    setUniName(e.target.value)
                }} value={uniName}></Input>
                <InputNumber
                    onFocus={()=>{
                        setTypeUni("price")
                    }}
                    style={{marginTop:20}} placeholder={"Цена"} onChange={e =>{
                    setUniPrice(e ? e : 0)
                }} value={uniPrice}></InputNumber>
                <KeyboardComp show={true} numOnly={typerUni === "price"} setSelected={typerUni === "name" ? setUniName : setUniPrice}/>
            </Modal>

            <Modal width={"100%"} centered destroyOnClose title={messager.message} cancelText={"Отмена"} open={isManyItemModalOpen} onOk={()=>{
                dispatch(flushMessage() )
                setManyItemModalOpen(false)
            }} onCancel={()=>{
                dispatch(flushMessage() )
                setManyItemModalOpen(false)
            }}>
                <div style={{position: "relative"}}>

                {messager.items?.map((item)=>{
                    return <Button type={"primary"} style={{margin: 5, height: 60}} onClick={()=>{
                        dispatch(changeCartByCode({code: item.id, isID: true}))
                        setManyItemModalOpen(false)
                        dispatch(flushMessage())
                    }}>{item.name}</Button>
                })}

                </div>

            </Modal>

            <Modal footer={[]} open={openAddItem} onOk={()=>setOpenAddItem(false)} onCancel={()=>{setOpenAddItem(false)}} destroyOnClose={true}>
                <AddItem mini={true} setOpen={setOpenAddItem}/>
            </Modal>

            <KeyboardComp numOnly={false} show={nameSearchTypeBar} setSelected={setSelectValue}/>

        </div>
    )
        ;
};

export default CashPage;