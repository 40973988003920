import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Calendar, CalendarProps, Card, FloatButton, Spin, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import API from "../../API/API";
import {printReceipt} from "./CashPage";
import {useDispatch, useSelector} from "react-redux";
import {setReceiptCache} from "../../store/mainSlice";
import {Dayjs} from "dayjs";

const CashReceipts = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [onMargin, setOnMargin] = useState(false)
    const [date, setDate] = useState(()=>(new Date()).toLocaleDateString())
    const receiptCache: {cash: number,
        cartItems: { name: string, price: number, quantity: number}[],
        uni: string,
        type: number,
        comments: string,
        margin: number}[] = useSelector((state: any)=>state.main.receiptCache)
    const dispatch = useDispatch()

    const [cashiers, setCashiers] = useState<{name: string, margin: number, total: number, sellCount: number}[]>([])


    useEffect( () => {
        setLoading(true)
        API.getReceiptsAtDay(date)
            .then(data=>{
                let x = data.data.toSorted((a: any, b: any) => {
                    if(a.uni > b.uni){
                        return 1
                    }
                    return -1
                })
                let newCashiers: {name: string, margin: number, total: number, sellCount: number}[] = []
                x.forEach((rec: any)=>{
                    let cca = newCashiers.find(cashier=> cashier.name === rec.admin)
                    if(cca){
                        cca.sellCount++
                        cca.total = cca.total + rec.cash
                        cca.margin = cca.margin + rec.margin
                    }else {
                        newCashiers.push({
                            name: rec.admin,
                            sellCount: 1,
                            total: rec.cash,
                            margin: rec.margin
                        })
                    }
                })
                setCashiers(newCashiers)
                console.log("new: ", newCashiers)
                setData(x)
                setLoading(false)
            })
        if(receiptCache.length > 0){
            let x = 0, indexes: number[] = [], promises: Promise<number>[] = []

            let copyCache = Object.assign([], receiptCache)
            receiptCache.forEach( async (receipt, index)=>{
                let pr: Promise<number> = new Promise((resolve, reject) => {
                    API.createReceipt(receipt.cartItems, receipt.cash, receipt.uni, receipt.type, receipt.margin, receipt.comments).then(()=>{
                        resolve(index)
                    }).catch(()=>{
                        reject(index)
                    })
                })
                promises.push(pr)
            })

            Promise.allSettled(promises).then((results)=>{
                results.forEach(res=>{
                    if(res.status === "fulfilled") {
                        indexes.push(res.value)
                    }
                })
            }).finally(()=>{
                indexes.sort()
                indexes.reverse()
                indexes.forEach(val=>{
                    copyCache.splice(val, 1)
                })
                console.log("ccc: ",copyCache)
                dispatch(setReceiptCache(copyCache))
            })

        }
    }, [receiptCache, date]);


    return (
        <div style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <FloatButton type={"primary"} icon={<ArrowLeftOutlined/>} onClick={() => {
                navigate("/cash", {replace: true})
            }}></FloatButton>


            {
                loading && <div style={{
                    background: "rgba(0,0,0,0.35)",
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}><Spin size={"large"}></Spin></div>
            }
            <div style={{width: 300,
                border: `1px solid black`,
                borderRadius: 5}}>
                <Calendar fullscreen={false} onChange={(e)=>{
                    console.log(e.format("DD.MM.YYYY"))
                    setDate(e.format("DD.MM.YYYY"))
                }}/>
            </div>
            <div onDoubleClick={e=>{
                setOnMargin(f=> !f)
            }} style={{position: "absolute", right: 50, top: 50}}>
                {cashiers.map((cashier)=>{
                    return <div>
                        {onMargin ? cashier.name + ":" + cashier.sellCount + " маржа на " + cashier.margin +"тг" : cashier.name + ":" + cashier.sellCount + " продаж на " + cashier.total +"тг"}
                    </div>
                })
                }
            </div>
            {data.filter((receipt: any) => {
                return receipt.cash > 0
            }).map((receipt: any) => {
                let cc = 0
                return <Card key={receipt.uni} actions={[<Button onClick={() => {
                    let count = 0
                    let cashItms: any = {}
                    cashItms.items = receipt.cartItems.map((item: any) => {
                        count = count + item.quantity * item.price
                        return {
                            name: item.name,
                            id: item.name,
                            quantity: item.quantity,
                            masterPrice: item.price,
                            highPrice: item.price,
                            lowPrice: item.price,
                            barcode: ""
                        }
                    })
                    printReceipt(cashItms, receipt.cash, 1, count, receipt.createdBy)
                }} type={"primary"}>Распечатать</Button>,
                    <Typography.Text>Принято {receipt.cash + "тг"}</Typography.Text>]} bodyStyle={{overflowY: "auto"}}
                             style={{width: "80%", marginBottom: 30}} title={receipt.uni}>
                    {
                        receipt.cartItems.map((item: any) => {
                            cc = cc + item.quantity * item.price
                            // return <div >{item.price}</div>
                            return <div>{item.name + " " + item.quantity + " * " + item.price + " = " + item.quantity * item.price}</div>
                            // return <div >{item.name + " " + item.quantity + " * " + item.price + " = " + item.quantity * item.price}</div>
                            // return <div >{item.name + " " + item.quantity + " * " + item.price + " = " + item.quantity * item.price}</div>
                        })
                    }
                    <span style={{color: "red"}}>{"Сумма: " + cc + "тг"}</span> <br/>
                    {receipt.margin && receipt.margin > 0 ?
                        <span style={{color: "red"}}>{"Маржа: " + receipt.margin + "тг"}</span> : <></>}

                    {receipt.type !== undefined ?
                        <><br/><span
                            style={{color: "black"}}>{"Тип: " + (receipt.type === 3 ? "Розничный" : receipt.type === 2 ? "Оптовый" : "Супер оптовый")}</span></>
                        : <></>
                    }

                    {receipt.admin !== undefined ?
                        <><br/><span style={{color: "black"}}>{"Кассир: " + receipt.admin}</span></>
                        : <></>
                    }
                    {receipt.comments !== "" ?
                        <><br/><span style={{color: "black"}}>{"Примечания: " + receipt.comments}</span></>
                        : <></>
                    }

                </Card>
            })}
        </div>
    );
};

export default CashReceipts;