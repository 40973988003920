import {Button, Input, InputRef, Modal, Space, Table, TableColumnsType, TableColumnType} from "antd";
import {ItemInterface} from "../../../store/Interfaces";
import {SearchOutlined} from "@ant-design/icons";
import React, {useMemo, useRef, useState} from "react";
// import Highlight from "react-highlight-words"
import {FilterDropdownProps} from "antd/es/table/interface";
import {useDispatch, useSelector} from "react-redux";
import "./ItemsTable.css"
import {ColumnType} from "antd/es/list";
import {ColumnGroupType, ColumnsType} from "antd/lib/table";

interface DataType {
    id: string;
    name: string;
    quantity: number;
    inBox: number;
    inBlock: number;
    price: number;
    masterPrice: number;
    lowPrice: number;
    highPrice: number;
    barcode: string
    inFastCash: boolean
}

type DataIndex = keyof DataType;

const ItemsTable = () => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const data: DataType[] = useSelector((state: any)=>state.main.items)
    const datacopy = useRef(data)
    const dispatch = useDispatch();

    const [changedItems, setChangedItems] = useState<DataType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: DataIndex,) => {
        console.log("ss: ", selectedKeys)
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => {
            // console.log("sel: ", selectedKeys)
            return (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        )},
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
        ),
        onFilter: (value, record) => {
        // if (record && record.hasOwnProperty(dataIndex) && record[dataIndex]){
            if(record[dataIndex].toString().toLowerCase().includes((value as string).toLowerCase())){
                console.log(record[dataIndex].toString().toLowerCase(), " : ", (value as string).toString().toLowerCase())
                console.log(JSON.stringify(record[dataIndex]))
            }
            return record[dataIndex].toString().toLowerCase().includes((value as string).toLowerCase())
        // }
        // else return false
        },
        filterDropdownProps: {
            onOpenChange(open) {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },

    });

    // console.log(changedItems)
    let columns: TableColumnsType<DataType> = []
    for(const keyg of ["name"]){//, "quantity", "inBox", "inBlock", "price", "masterPrice","lowPrice", "highPrice", "barcode"]){
        // console.log(keyg)
        const addingColumn: any = {
            title: capitalizeFirstLetter(keyg),
            dataIndex: keyg,
            key: keyg,
            width: keyg === "name" ? "25%" :  keyg === "barcode" ? "12%" : "7%",
            render : (value: string, _: any, index: number) => {
                return <input
                            id={index + "inpp"}
                            type={keyg === "name" || keyg === "barcode" || keyg === "id" ? "text" : "number"}
                            onWheel={(e) => (e.target as HTMLInputElement).blur()}
                            onChange={(e) => {
                                setChangedItems((changedItems2) => {
                                    const newChangedItems: DataType[] = Object.assign([], changedItems2)
                                    const finder = changedItems2.findIndex(it => it.id === data[index].id)
                                    if (finder !== -1) {
                                        console.log("found")
                                        // @ts-ignore
                                        if (typeof newChangedItems[finder][keyg] === "number") {
                                            // @ts-ignore
                                            newChangedItems[finder][keyg] = Number(e.target.value)
                                            // @ts-ignore
                                            datacopy.current[index][keyg] = Number(e.target.value)
                                        } else {
                                            // @ts-ignore
                                            newChangedItems[finder][keyg] = e.target.value
                                            // @ts-ignore
                                            datacopy.current[index][keyg] = Number(e.target.value)
                                        }
                                        // @ts-ignore
                                        if (data[index][keyg].toString() === e.target.value) {
                                            newChangedItems.splice(finder, 1)
                                            // @ts-ignore
                                            datacopy.current[index][keyg] = data[index][keyg]
                                        }
                                    } else {
                                        console.log("not found")
                                        const changingItem: DataType = {
                                            id: data[index].id,
                                            name: data[index].name,
                                            quantity: data[index].quantity,
                                            inBox: data[index].inBox,
                                            inBlock: data[index].inBlock,
                                            price: data[index].price,
                                            masterPrice: data[index].masterPrice,
                                            lowPrice: data[index].lowPrice,
                                            highPrice: data[index].highPrice,
                                            barcode: data[index].barcode,
                                            inFastCash: data[index].inFastCash,
                                        }
                                        // @ts-ignore
                                        if (typeof changingItem[keyg] === "number") {
                                            // @ts-ignore
                                            changingItem[keyg] = Number(e.target.value)
                                            // @ts-ignore
                                            datacopy.current[index][keyg] = Number(e.target.value)
                                        } else {
                                            // @ts-ignore
                                            changingItem[keyg] = e.target.value
                                            // @ts-ignore
                                            datacopy.current[index][keyg] = e.target.value
                                        }
                                        newChangedItems.push(changingItem)
                                    }
                                    return newChangedItems
                                })
                            }}
                            className={"ghost-input"}
                            defaultValue={
                                // @ts-ignore
                                datacopy.current[index][keyg].toString()
                            }/>
            }
        }
        if (keyg === "name") {
            columns.push({...addingColumn, ...getColumnSearchProps("name")})
        }
        else columns.push(addingColumn)
    }

    console.log(datacopy.current)

    const [changeModalIsOpen, setChangeModalOpen] = useState(false)


    const tabler = useMemo(()=>{
        console.log("ren")
        return <Table<DataType> pagination={false} columns={columns} dataSource={data}/>
    }, [])
    // }, [data, searchText, searchedColumn])

    return(
        <>
            {tabler}
            <Modal open={changeModalIsOpen}
                   onOk={()=>{
                       setChangeModalOpen(false)
                   }}
                   onCancel={()=>{
                       setChangeModalOpen(false)
                   }}
            ></Modal>
        </>
    )
}

export default ItemsTable;

export function getRandomIntInclusive(min: number, max: number) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}
export const codePutter = (dataSource: ItemInterface[], newBarcoders: ItemInterface[]):string =>{
    let newB = "sh-" + getRandomIntInclusive(100000, 999999)
    let index = dataSource.findIndex(oneData => oneData.barcode === newB)
    if(index === -1) {
        index = newBarcoders.findIndex(oneData => oneData.barcode === newB)
    }
    if(index === -1){
        return newB
    }else
        return codePutter(dataSource, newBarcoders)
}
function capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}